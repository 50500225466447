<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="name" :label="$t('name')">
                <ElInput
                  v-model="form.name"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="reference" :label="$t('reference')">
                <ElInput
                  v-model="form.reference"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="ean" :label="$t('ean')">
                <ElInput
                  v-model="form.ean"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="barcode" :label="$t('barcode')">
                <ElInput
                  v-model="form.barcode"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="cost" :label="$t('cost')">
                <ElInputNumber
                  v-model="form.cost"
                  type="number"
                  step="0.01"
                  min="0"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="price" :label="$t('price')">
                <ElInputNumber
                  v-model="form.price"
                  type="number"
                  step="0.01"
                  min="0"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="tax_rate_uuid"
                :label="$t('tax_rate')"
                class="autocomplete-new-entity"
              >
                <el-select-v2
                  :key="getTaxRateKey()"
                  v-loading="getTaxRateKey() === 0"
                  v-model="form.tax_rate_uuid"
                  :remote-method="searchTaxRates"
                  :placeholder="$t('select_a_tax_rate')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchTaxRates()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewTaxRateDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12" />
            <ElCol
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewTaxRateDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_tax_rate') }}
        </span>
      </template>
      <QuickCreateTaxRateDialog
        @create=";(showNewTaxRateDialog = false), createTaxRate($event)"
        @cancel="showNewTaxRateDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_PRODUCT from '@/graphql/products/mutation/createProduct.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  invoice: {
    type: Object,
    default: () => ({})
  }
})

const form = reactive({
  name: '',
  reference: '',
  ean: '',
  barcode: '',
  cost: 0,
  price: 0,
  enabled: true,
  tax_rate_uuid: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewTaxRateDialog = ref(false)

const rules = {
  name: { required },
  tax_rate_uuid: { required }
}

onMounted(() => {
  initialTaxRates()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  name: [
    {
      required: true,
      message: t('name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  tax_rate_uuid: [
    {
      required: true,
      message: t('tax_rate_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_PRODUCT, {
    name: form.name,
    reference: form.reference,
    ean: form.ean,
    barcode: form.barcode,
    cost: form.cost ? parseInt(form.cost * 100) : 0,
    price: form.price ? parseInt(form.price * 100) : 0,
    enabled: form.enabled,
    tax_rate_uuid: form.tax_rate_uuid
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createProduct ?? null)
    loading.value = false
  }
}

const createTaxRate = (event) => {
  if (event?.uuid) {
    form.tax_rate_uuid = event.uuid
    form.tax_rate = event
    searchTaxRates(event.name, form.tax_rate, null, true)
  }
}
</script>
